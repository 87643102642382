header{
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .4em 4em;
  background: $clr-primary;
  border-bottom: 1px solid #cbe6d9;
  z-index: 10;
  transition: .2s;
  &.scrolled{
    background: #ffffff;
    .site-logo{
      width: 100px;
    }
  }
  .site-logo{
    width: 120px;
    height: auto;
    z-index: 9;
    transition: .2s;
    img{
      width: 100%;
      height: auto;
    }  
  }
  .primary-navigation{
    z-index: 8;
    flex-grow: 70%;
    .nav-ul{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.8em;
      li{
        list-style: none;
        a{
          position: relative;
          display: inline-block;
          font-family: $ff-text;
          color: $clr-black;
          font-weight: 400;
          &::after{
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            transform: scaleX(0);
            width: 100%;
            height: 2px;
            background-color: $clr-accent-dark;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
        }
        &:hover{
          a::after{
            transform: scaleX(1);
            transform-origin: bottom left;
          }
        }
        &.active{
          a{
            &::after{
              transform: scaleX(1);
              transform-origin: bottom left;
            }
          }
        }
      }
    }
  }
  .nav-actions{
    display: flex;
    align-items: center;
    gap: 1.2em;
    z-index: 8;
    .button-fill-dark{
      font-size: 1em;
      padding: .6em 1.8em;
    }
    .burger-bar{
      position: relative;
      display: none;
      width: 30px;
      height: 30px;
      background: $clr-secondary;
      border-radius: 50%;
      padding: 1.2em;
      cursor: pointer;
      .top-line,
      .mid-line,
      .bottom-line{
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 45%;
        height: 2px;
        background: $clr-white;
        transition: .5s;
      }
      .top-line{
        top: 35%;
      }
      .mid-line{
        top: 50%;
      }
      .bottom-line{
        top: 65%;
      }
      &.clicked{
        .top-line,
        .mid-line{
          top: 50%;
          transform: translate(-50%, -50%) rotate(135deg);
          height: 1.5px;
        } 
        .bottom-line{
          top: 50%;
          transform: translate(-50%, -50%) rotate(225deg);
          height: 1.5px;
        }
      }
    }
  }
}

@media screen and (max-width: 1087px){
  header{
    padding: .8em 1.3em;  
  }
}

@media screen and (max-width: 890px){
  header{
    .site-logo{
      width: 100px;
    }
    &.header-clicked{
      .primary-navigation{
        opacity: 1;
        pointer-events: auto;
      }
    }
    .primary-navigation{
      position: absolute;
      top: 0;
      left: 0;
      display: grid;
      justify-content: start;
      align-items: center;
      width: 100%;
      height: 100dvh;
      background: $clr-primary;
      transition: .2s cubic-bezier(0.1, 0.1, 0.5, 0.9);
      opacity: 0;
      pointer-events: none;
      .nav-ul{
        flex-direction: column;
        align-items: flex-start;
        gap: .5em;
        margin-left: 1.7em;
        li{
          a{
            font-size: 2em;
            font-weight: 500;
            &::after{
              height: 3px;
            }
          }
        }
      }
    }
    .nav-actions{
      gap: 1.5em;
      .button-fill-dark{
        padding: .5em 1.4em;  
      }
      .burger-bar{
        display: block;
      }
    }  
  }
}