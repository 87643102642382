@import "../../components/global";
@import "../../components/accordion";

#uvod{
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  width: 100%;
  background: $clr-primary;
  padding: 4em 17em 4em 6em;
  .uvod-text{
    transform: translateY(-40%);
    z-index: 2;
    h1{
      font-size: 6.1em;
    }
    p{
      margin: 1em 0 2em;
      font-size: 1.4em;
    }
  }
  .uvod-foto{
    position: relative;
    width: 60%;
    height: 80%;
    z-index: 2;
    img{
      border-radius: 15px;
      width: 100%;
      height: 100%;
    }
  }
  .uvod-hero{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: auto;
    z-index: 1;
  }
}

@media screen and (max-width: 106.25rem){ // 1700px
  #uvod{
    padding: 5em 6em;
    .uvod-text{
      h1{
        font-size: 5.5em;
      }
    }
  }
}
@media screen and (max-width: 97.5rem){ // 1560px
  #uvod{
    padding: 4em 4em; 
    .uvod-text{
      h1{
        font-size: 5.3em;
      }
    }
    .uvod-foto{
      position: relative;
      width: 80%;
    }
  }
}
@media screen and (max-width: 97.5rem){ // 1380px
  #uvod{
    .uvod-text{
      h1{
        font-size: 5em;
      }
    }
    .uvod-foto{
      width: 80%;
    }
  }
}
@media screen and (max-width: 77.5rem){ // 1240px
  #uvod{
    .uvod-text{
      h1{
        font-size: 4em;
      }
    }
  }
}
@media screen and (max-width: 65rem){ // 1040px
  #uvod{
    .uvod-text{
      h1{
        font-size: 3.7em;
      }
    }
  }
}
@media screen and (max-width: 65rem){ // 995px
  #uvod{
    flex-wrap: wrap;
    .uvod-text{
      transform: translateY(0);
      width: 100%;
      margin-top: 2em;
      h1{
        width: 60%;
        font-size: 3.7em;
      }
      p{
        margin: 1em 0 1.3em;
      }
    }
    .uvod-foto{
      width: 70%;
      margin: 3em 0 0 auto;
    }
  } 
}
@media screen and (max-width: 55.625rem){ // 890px
  #uvod{
    .uvod-text{
      h1{
        width: 80%;
      }
    }
    .uvod-foto{
      width: 90%;
    }
  } 
}
@media screen and (max-width: 43.9375rem){ // 703px
  #uvod{
    .uvod-text{
      h1{
        width: 100%;
      }
    }
  } 
}
@media screen and (max-width: 37rem){ // 592px
  #uvod{
    padding: 2em;
    .uvod-text{
      h1{
        font-size: 3em;
      }
    }
    .uvod-foto{
      margin-top: 1em;
      width: 100%;
    }
  } 
}

#benefity{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5em;
  width: 60%;
  margin: 8em auto 0 auto;
  padding: 4em 4em 10em 4em;
  color: $clr-white;
  background: $clr-secondary;
  border-radius: 10px;
  h2{
    font-family: $ff-text;
    font-weight: 400;
  }
  .skills{
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 5em;
    width: 100%;
    padding: 0 2em;
    div{
      text-align: center;
      p{
        white-space: nowrap;
      }
    }
  }
}
@media screen and (max-width: 1600px){ // 1600px
  #benefity{
    width: 75%;
  }
}
@media screen and (max-width: 65rem){ // 1040px
  #benefity{
    width: 90%;
    flex-wrap: wrap;
    gap: 1em;
    h2{
      max-width: unset;
      text-align: center;
    }
    p{
      max-width: unset;
    }
  }
}
@media screen and (max-width: 678px){
  #benefity{
    .skills{
      gap: 3em;
    }
  }
}
@media screen and (max-width: 579px){
  #benefity{
    .skills{
      bottom: 5%;
      gap: 2em;
      flex-wrap: wrap;
    }
  }
}
@media screen and (max-width: 523px){
  #benefity{
    padding: 2em 2em 15em 2em;
    .skills{
      bottom: -5%;
    }
  }
}

#moje-sluzby{
  width: 100%;
  padding: 4em 2em;
  margin-top: 8em;
  background: $clr-primary;
  h2{
    text-align: center;
  }
  p{
    text-align: center;
    margin: 1em auto 0;
  }
  h3{
    text-align: center;
    margin-top: 1em;
  }
  .program-services{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2em;
    flex-wrap: wrap;
    margin-top: 2em;
    .program-service{
      position: relative;
      width: 400px;
      padding: 3.2em 2.5em;
      border-radius: 20px;
      background: #fff;
      -webkit-box-shadow: 0px 0px 20px 8px rgba(0, 0, 0, 0.04);
      -moz-box-shadow: 0px 0px 20px 8px rgba(0, 0, 0, 0.04);
      box-shadow: 0px 0px 20px 8px rgba(0, 0, 0, 0.04);
      /*-webkit-box-shadow: 5px 7px 0px 4px rgba(0, 0, 0, 0.06);
      -moz-box-shadow: 5px 7px 0px 4px rgba(0, 0, 0, 0.06);
      box-shadow: 5px 7px 0px 4px rgba(0, 0, 0, 0.06);*/
      &.popular{
        background: #c03855;
        .popular-sign{
          position: absolute;
          top: 0;
          right: 0;
          background: #F9D375;   
          padding: .3em .8em;
          border-radius: 0 18px 0 10px;
          font-weight: 400;
        }
        h4,
        .bold,
        .services-list{
          color: #fff;
        }
        .services-list{
          li{
            &::before{
              background-image: url("../../../images/check-green-light.svg");
            }
          }
        }
        .dotted-line{
          border-bottom: 2px dashed #D5D5D5;
        }
      }
      h4{
        font-size: clamp(1.5rem, 1.5vw, 1.9rem);
      }
      .bold{
        text-align: left;
        font-size: 1.2em;
        font-weight: 400;
        margin-top: 1.2em;
      }
      .services-list{
        position: relative;
        margin: .5em 0 0 2em;
        li{
          position: relative;
          margin-bottom: 1em;
          &::before{
            content: '';
            position: absolute;
            top: .2em;
            left: -1.8em;
            width: 1em;
            height: 1em;
            background-repeat: no-repeat;
            background-image: url("../../../images/check-green-dark.svg");
          }
        }
      }
      .dotted-line{
        margin: 1.7em 0;
        border-bottom: 2px dashed #707070;
      }
      a{
        display: block;
        text-align: center;
        width: 100%;
      }
    }
  }


  /*
  .programs-services{
    display: flex;
    justify-content: center;
    gap: 2em;
    flex-wrap: wrap;
    margin-top: 3em;
    .program-service{
      background: #cfeadd;
      width: 600px;
      padding: 3em 4em;
      border-radius: 10px;
      -webkit-box-shadow: 5px 7px 0px 4px rgba(0, 0, 0, 0.06);
      -moz-box-shadow: 5px 7px 0px 4px rgba(0, 0, 0, 0.06);
      box-shadow: 5px 7px 0px 4px rgba(0, 0, 0, 0.06);
      h3{
        text-align: center;
      }
      ul{
        margin-top: 2em;
        list-style-position: outside;
        li{
          position: relative;
          margin-top: 1em;
          &.bold-list-element{
            font-weight: 400;
          }
          &::before{
            content: '';
            position: absolute;
            top: .2em;
            left: -1.5em;
            width: 10em;
            height: 10em;
            background-repeat: no-repeat;
            background-image: url("../../../images/arrow-right.svg");
          }
        }
      }
    }
  }*/
}
@media screen and (max-width: 82.0625rem){ // 1313px
  #moje-sluzby{
    .programs-services{
      .program-service{
        width: 500px;
      }
    }
  }
}
@media screen and (max-width: 82.0625rem){// 1112px
  #moje-sluzby{
    .programs-services{
      .program-service{
        width: 70%;
      }
    }
  }
}
@media screen and (max-width: 69.5rem){ // 840px
  #moje-sluzby{
    padding: 3em 1em;
    p{
      max-width: 100%;
    }
    .programs-services{
      .program-service{
        width: 100%;
        padding: 2em;
        ul{
          margin: 0 1em;  
        }
      }
    }
  }
}

#s-cim-vam-pomohu{
  position: relative;
  width: 80%;
  margin: 8em auto 0;
  padding: 4em;
  color: $clr-white;
  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 85%;
    height: 100%;
    background: $clr-secondary;  
    border-radius: 10px;
    z-index: -1;
  }
  .pomoc-content{
    max-width: 60%;
    p{
      max-width: 80%;
      margin: 1.3em 0 2em;
    }
    .checkpoints{
      max-width: 80%;
      margin: 2em 0 2em 1.5em;
      list-style-image: url('../../../images/check-light.svg');
      list-style-position: outside;
      li{
        &:not(:first-child){
          padding-top: 1em;
        }
      }
    }
    .cta-paragraph{
      margin-bottom: 1em;
    }
  }
  .pomoc-foto{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    svg{
      width: 450px;
      height: 500px;
    }
  }
}

@media screen and (max-width: 92.5rem){ // 1480px
  #s-cim-vam-pomohu{
    width: 70%;
  }
}
@media screen and (max-width: 83.4375rem){ // 1335px
  #s-cim-vam-pomohu{
    width: 85%;
  }
}
@media screen and (max-width: 67.5rem){ // 1080px
  #s-cim-vam-pomohu{
    width: 90%;
    .pomoc-foto{
      svg{
        width: 400px;
        height: 400px;
      }
    }
  }
}
@media screen and (max-width: 51.5625rem){ // 825px
  #s-cim-vam-pomohu{
    .pomoc-content{
      max-width: 80%;
    }
    .pomoc-foto{
      svg{
        width: 350px;
        height: 400px;
      }
    }
  }
}
@media screen and (max-width: 51.5625rem){ // 825px
  #s-cim-vam-pomohu{
    padding: 2em;
    &::after{
      width: 100%;
    }
    .pomoc-content{
      max-width: 100%;
      p{
        max-width: 100%;
      }
    }
    .pomoc-foto{
      svg{
        display: none;
      }
    }
  }
}


#o-mne{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8em;
  margin-top: 8em;
  padding: 4em 6em;
  background: $clr-primary;
  overflow: hidden; 
  .o-mne-content{
    width: 60%;
    z-index: 2;
    p{
      margin: 1.7em 0 3em;
    }
  }
  .o-mne-foto{
    position: relative;
    z-index: 2;
    width: 70%;
    height: 80%;
    img{
      border-radius: 10px;
      width: 100%;
      height: 100%;
    }
  } 
  .o-mne-background{
    position: absolute;
    top: 80%;
    left: 10%;
    width: 30%;
    transform: translate(-50%,-50%);
    z-index: 1;
  }
}

@media screen and (max-width: 66.75rem){ // 1068px
  #o-mne{
    padding: 5em 3em 6em 3em;
    .o-mne-content{
      width: 70%;
    }  
  }
}

@media screen and (max-width: 66.75rem){ // 875px
  #o-mne{
    gap: 4em;
    .o-mne-content,
    .o-mne-foto{
      width: 90%;
    }  
  }
}

@media screen and (max-width: 66.75rem){// 780px
  #o-mne{
    padding: 5em 2em 6em 2em;
    flex-wrap: wrap;
    .o-mne-content,
    .o-mne-foto{
      width: 97%;
    }  
  }
}


/*
@media screen and (max-width: 62.5rem){ // 1000px
  #o-mne{
    .o-mne-content{
      max-width: 70%;
    }  
  }
}
@media screen and (max-width: 38.125rem){ // 610px
  #o-mne{
    .o-mne-content{
      max-width: 100%;
    }  
  }
}*/

#jak-to-u-me-funguje{
  margin-top: 8em;
  h2{
    text-align: center;
  } 
  .jak-to-funguje-container{
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 1.5em;
    flex-wrap: wrap;
    margin: 3em auto 0 auto;
    width: 60%;
    .step{
      position: relative;
      width: 350px;
      height: 220px;
      padding: 2em;
      background: $clr-primary;
      border-radius: 10px;
      .step-number{
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        font-size: 12em;
        font-weight: 500;
        margin-bottom: .2em;
        color: #d8e6df;
        z-index: 1;
      }
      h3,
      p{
        position: relative;
        z-index: 2;
      }
      p{
        margin-top: 1em;
      }
    }
  }
}
@media screen and (max-width: 114.375rem){ // 1830px
  #jak-to-u-me-funguje{
    .jak-to-funguje-container{
      width: 80%;
    }
  }
}
@media screen and (max-width: 114.375rem){ // 1377px
  #jak-to-u-me-funguje{
    .jak-to-funguje-container{
      width: 90%;
    }
  }
}
@media screen and (max-width: 114.375rem){ // 1220px
  #jak-to-u-me-funguje{
    padding: 0 2em;
    .jak-to-funguje-container{
      width: 100%;
    }
  }
}
@media screen and (max-width: 52.5rem){ // 840px
  #jak-to-u-me-funguje{
    .jak-to-funguje-container{
      div{
        width: 100%;
      }
    }
  }
}

#reference{
  margin-top: 8em;
  padding-top: 4em;
  h2{
    text-align: center;  
  }
  h3{
    padding-left: 2em;
    margin: 1.5em 0 1em;
  }
  .reference-container{
    &:nth-of-type(2){
      padding: 0 3em;
    }
    .reference-card{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 2em;
      background: $clr-primary;
      border-radius: 10px;
      cursor: pointer;
      .reference-text{
        width: 100%;
        p{
          margin-bottom: 1em;
        }
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }  
  }
  .reference-call-to-action{
    text-align: center;
    margin-top: 3em;
  }
}
@media screen and (max-width: 75rem){ // 1200px
  #reference{
    .reference-container{
      .reference-card{
        flex-wrap: wrap;
        gap: 3em;
        .reference-text{
          width: 100%;
        }
        .reference-image{
          width: 100%;
        }
      }
    }  
  }  
}
@media screen and (max-width: 36.25rem){
  #reference{
    h3{
      text-align: center;
      padding: 0;
      margin: 2em 0 .7em;
    }
    .reference-container{
      &:nth-of-type(2){
        padding: 1em;
      }
    }
  }
}

.green-background{
  background: $clr-primary;
  padding: 0 4em 4em 4em;
  margin-top: 8em;
}
@media screen and (max-width: 39.5rem){ // 632px
  .green-background{
    padding: 5em 1.5em;
  }
}

#casto-kladene-dotazy{
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 5em;
  flex-wrap: wrap;
  padding-top: 4em;
  .faq-heading{
    h2{
      margin-bottom: 1em;
    }
  }
}

#oteviraci-doba{
  display: grid;
  place-items: center;
  margin-top: 6em;
  h2,
  p{
    text-align: center;
  }
  p{
    max-width: 50%;
    margin: 1em auto 0;
  }
  .button-fill-dark{
    margin-top: 1.8em;
  }
}

@media screen and (max-width: 52.5rem){ // 840px
  #oteviraci-doba{
    p{
      max-width: 90%;
    }
  }
}

#cenik{
  margin-top: 8em;
  padding-top: 4em;
  h2{
    text-align: center;
  }
  .cenik-sluzeb-container{
    margin-top: 2em;
    .cenik-sluzeb-item{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 60%;
      margin: 1em auto 0;
      .popis-ceny-sluzby{
        h4{
          margin: 0;
        }
      }
      .dots{
        flex-grow: 3;
        border-bottom: 2px dotted $clr-black;  
        margin: 0 .5em;
      }
      .cena-sluzby{
        white-space: nowrap;
      }
    }  
  }
}

@media screen and (max-width: 79.375rem){ // 1270px
  #cenik{
    .cenik-sluzeb-container{
      .cenik-sluzeb-item{
        width: 80%;
        .dots{
          margin: 0 1em;
        }
      }
    }
  }
}

@media screen and (max-width: 53.75rem){ // 860px
  #cenik{
    .cenik-sluzeb-container{
      .cenik-sluzeb-item{
        width: 90%;
      }
    }
  }
}

#kontakt{
  margin-top: 8em;
  padding: 4em;
  color: $clr-white;
  background: $clr-secondary;
  .kontakt-container{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    gap: 2em;
    .kontakt-left{
      width: 40%;
      .kontakt-ponuka{
        margin-top: 2em; 
        color: $clr-white-light; 
      }
      .kontakt-info{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 3em;
        margin-top: 5em;
        div{
          p{
            width: 70%;
            color: $clr-white-light;
          }
          ul{
            list-style: none;
            li{
              a{
                color: $clr-white-light;
                transition: .2s;
                &:hover{
                  color: #f57893;
                }
              }
              &:nth-child(3){
                margin-top: 1em;
                .light-color{
                  color: $clr-white-light;
                }
              }
            }
          }
        }
      }
      .kontakt-socials{
        margin-top: 2.5em;
      }
    }
    .kontakt-right{
      width: 30%;
      margin-top: 4em;
    }
  }
  .map{
    margin-top: 4em;
    border: none;  
    border-radius: 10px;
    width: 100%;
  }
}
@media screen and (max-width: 73.125rem){ // 1170px
  #kontakt{
    padding: 4em 2em;
    .kontakt-container{
      flex-wrap: wrap;
      .kontakt-left,
      .kontakt-right{
        width: 100%;
      }
    }
  }
}