/* Box sizing rules */
html {
  box-sizing: border-box;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Set core body defaults */
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Make images, videos and icons easier to work with */
img, picture, video, canvas, svg {
  display: block;
}

/* More sensible line-wrapping */
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
}

/* React Framework Specific */
#root, #__next {
  isolation: isolate;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.button-fill-light,
.button-fill-dark,
.button-fill-white,
.button-nofill-light {
  padding: 0.6em 1.8em;
  border-radius: 10em;
  color: #fff;
  font-size: clamp(0.9rem, 3vw, 1rem);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.button-fill-light.none,
.button-fill-dark.none,
.button-fill-white.none,
.button-nofill-light.none {
  display: none;
}
.button-fill-light .cta-icon,
.button-fill-dark .cta-icon,
.button-fill-white .cta-icon,
.button-nofill-light .cta-icon {
  display: inline-block;
}
.button-fill-light .cta-icon div svg path,
.button-fill-dark .cta-icon div svg path,
.button-fill-white .cta-icon div svg path,
.button-nofill-light .cta-icon div svg path {
  stroke: #fff;
}

.button-nofill-light {
  color: #000;
  border: 1px solid rgb(119, 117, 117);
  background: none;
  transition: 0.2s;
}
.button-nofill-light:hover {
  color: #f1f1e1;
  border-color: #DE5976;
  background: #DE5976;
}

.button-fill-light,
.button-fill-dark {
  display: inline-flex;
  align-items: center;
  gap: 0.7em;
}

.button-fill-light {
  background: #DE5976;
}

.button-fill-dark {
  background: #C74561;
  transition: 0.2s;
}
.button-fill-dark:hover {
  background: #c03855;
}

.button-fill-white {
  color: #000;
  background: #fff;
  transition: 0.2s;
}
.button-fill-white:hover {
  background: #fadae1;
}

@media screen and (max-width: 65rem) {
  .button-fill-light .cta-icon,
  .button-fill-dark .cta-icon {
    display: inline-block;
  }
  .button-fill-light .cta-icon div svg,
  .button-fill-dark .cta-icon div svg {
    height: 25px;
  }
}
button {
  cursor: pointer;
}

input[type=submit] {
  outline: none;
  border: none;
}

header {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4em 4em;
  background: #E2EFE9;
  border-bottom: 1px solid #cbe6d9;
  z-index: 10;
  transition: 0.2s;
}
header.scrolled {
  background: #ffffff;
}
header.scrolled .site-logo {
  width: 100px;
}
header .site-logo {
  width: 120px;
  height: auto;
  z-index: 9;
  transition: 0.2s;
}
header .site-logo img {
  width: 100%;
  height: auto;
}
header .primary-navigation {
  z-index: 8;
  flex-grow: 70%;
}
header .primary-navigation .nav-ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.8em;
}
header .primary-navigation .nav-ul li {
  list-style: none;
}
header .primary-navigation .nav-ul li a {
  position: relative;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  color: #000;
  font-weight: 400;
}
header .primary-navigation .nav-ul li a::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  transform: scaleX(0);
  width: 100%;
  height: 2px;
  background-color: #C74561;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
header .primary-navigation .nav-ul li:hover a::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
header .primary-navigation .nav-ul li.active a::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
header .nav-actions {
  display: flex;
  align-items: center;
  gap: 1.2em;
  z-index: 8;
}
header .nav-actions .button-fill-dark {
  font-size: 1em;
  padding: 0.6em 1.8em;
}
header .nav-actions .burger-bar {
  position: relative;
  display: none;
  width: 30px;
  height: 30px;
  background: #225B41;
  border-radius: 50%;
  padding: 1.2em;
  cursor: pointer;
}
header .nav-actions .burger-bar .top-line,
header .nav-actions .burger-bar .mid-line,
header .nav-actions .burger-bar .bottom-line {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 45%;
  height: 2px;
  background: #f1f1e1;
  transition: 0.5s;
}
header .nav-actions .burger-bar .top-line {
  top: 35%;
}
header .nav-actions .burger-bar .mid-line {
  top: 50%;
}
header .nav-actions .burger-bar .bottom-line {
  top: 65%;
}
header .nav-actions .burger-bar.clicked .top-line,
header .nav-actions .burger-bar.clicked .mid-line {
  top: 50%;
  transform: translate(-50%, -50%) rotate(135deg);
  height: 1.5px;
}
header .nav-actions .burger-bar.clicked .bottom-line {
  top: 50%;
  transform: translate(-50%, -50%) rotate(225deg);
  height: 1.5px;
}

@media screen and (max-width: 1087px) {
  header {
    padding: 0.8em 1.3em;
  }
}
@media screen and (max-width: 890px) {
  header .site-logo {
    width: 100px;
  }
  header.header-clicked .primary-navigation {
    opacity: 1;
    pointer-events: auto;
  }
  header .primary-navigation {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 100dvh;
    background: #E2EFE9;
    transition: 0.2s cubic-bezier(0.1, 0.1, 0.5, 0.9);
    opacity: 0;
    pointer-events: none;
  }
  header .primary-navigation .nav-ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5em;
    margin-left: 1.7em;
  }
  header .primary-navigation .nav-ul li a {
    font-size: 2em;
    font-weight: 500;
  }
  header .primary-navigation .nav-ul li a::after {
    height: 3px;
  }
  header .nav-actions {
    gap: 1.5em;
  }
  header .nav-actions .button-fill-dark {
    padding: 0.5em 1.4em;
  }
  header .nav-actions .burger-bar {
    display: block;
  }
}
.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background: #fff;
  z-index: 10;
}
.loader img,
.loader div {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader img {
  top: 50%;
}
.loader div {
  top: 70%;
}

.splide {
  position: relative;
}

.splide__track {
  margin-bottom: 2em;
}

.splide__arrows {
  position: absolute;
  bottom: 30px;
  right: 50px;
  width: 120px;
}
.splide__arrows .splide__arrow {
  border: 1px solid #225B41;
  border-radius: 50%;
}
.splide__arrows .splide__arrow:hover svg {
  fill: #225B41;
}
.splide__arrows .splide__arrow svg {
  width: 1.4em;
  height: 1.4em;
  fill: #225B41;
}

.splide__pagination .splide__pagination__page {
  background: #E2EFE9;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}
.splide__pagination .splide__pagination__page:hover {
  background: #DE5976;
}
.splide__pagination .splide__pagination__page.is-active {
  background: #C74561;
}

@media screen and (max-width: 36.25rem) {
  .splide {
    padding: 1em;
  }
  .splide__arrows {
    display: none;
  }
  .splide__pagination {
    bottom: 0.2em;
    gap: 0.3em;
  }
  .splide__pagination .splide__pagination__page {
    width: 15px;
    height: 15px;
  }
}
form {
  background: #cbecdc;
  padding: 2em;
  border-radius: 15px;
}
form .input-wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 2em;
  margin-bottom: 1em;
}
form .input-wrapper .input-content {
  display: grid;
  width: 100%;
}
form .input-wrapper .input-content label {
  font-size: 0.95em;
  font-weight: 400;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
  color: #000;
  margin-bottom: 0;
}
form .input-wrapper .input-content label::after {
  content: "*";
  position: relative;
  bottom: 5px;
  margin-left: 0.2em;
  color: #c03855;
  font-size: 0.9em;
}
form .input-wrapper .input-content input[type=text],
form .input-wrapper .input-content input[type=email],
form .input-wrapper .input-content textarea {
  width: 100%;
  padding: 0.5em;
  margin-top: 0.5em;
  color: #000;
  font-weight: 400;
  background: none;
  outline: none;
  border: 1px solid rgb(119, 117, 117);
  border-radius: 5px;
  transition: 0.3s;
}
form .input-wrapper .input-content input[type=text]:hover,
form .input-wrapper .input-content input[type=email]:hover,
form .input-wrapper .input-content textarea:hover {
  border: 1px solid #000;
}
form .input-wrapper .input-content input[type=text]:not(:-moz-placeholder-shown), form .input-wrapper .input-content input[type=email]:not(:-moz-placeholder-shown), form .input-wrapper .input-content textarea:not(:-moz-placeholder-shown) {
  border: 1px solid #000;
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.08);
}
form .input-wrapper .input-content input[type=text]:focus, form .input-wrapper .input-content input[type=text]:not(:placeholder-shown),
form .input-wrapper .input-content input[type=email]:focus,
form .input-wrapper .input-content input[type=email]:not(:placeholder-shown),
form .input-wrapper .input-content textarea:focus,
form .input-wrapper .input-content textarea:not(:placeholder-shown) {
  border: 1px solid #000;
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.08);
}
form .input-wrapper .input-content textarea {
  resize: vertical;
}
form .form-message {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 0.2em;
  color: #ef7d60;
  margin-top: 0.5em;
}
form .form-message svg {
  width: 15px;
  height: 15px;
  fill: #ef7d60;
}
form input[type=submit] {
  font-size: 1em;
  cursor: pointer;
  margin-top: 1em;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.514);
  opacity: 0;
  z-index: -1;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.popup.show {
  opacity: 1;
  z-index: 11;
}
.popup .popup-box {
  margin: 0 1em;
  padding: 2em 3em;
  background: #fff;
  color: #000;
  border-radius: 15px;
}
.popup .popup-box .popup-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup .popup-box .popup-icon svg {
  width: -moz-min-content;
  width: min-content;
  height: 100px;
}
.popup .popup-box .popup-message {
  margin-top: 2em;
  text-align: center;
}
.popup .popup-box .popup-message p {
  font-weight: 400;
}
.popup .popup-box .popup-message .button-nofill-light {
  font-size: 0.9em;
  margin-top: 2em;
}

footer {
  margin-top: 8em;
}
footer a {
  color: #DE5976;
  font-weight: 400;
}
footer a:hover {
  color: #C74561;
}
footer .footer-top,
footer .footer-bottom {
  padding: 0 9em;
}
footer .footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
}
footer .footer-top .footer-image a img {
  width: 300px;
}
footer .footer-top .footer-links {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 5em;
}
footer .footer-top .footer-links div:last-child {
  flex-basis: 33.33%;
}
footer .footer-top .footer-links div h5 {
  font-weight: 500;
}
footer .footer-top .footer-links div ul li {
  margin-bottom: 0.2em;
}
footer .footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-top: 3em;
  padding-top: 0.8em !important;
  padding-bottom: 0.5em !important;
  background: #D4E6DE;
}

@media screen and (max-width: 83.125rem) {
  footer .footer-top,
  footer .footer-bottom {
    padding: 0 4em;
  }
}
@media screen and (max-width: 71.875rem) {
  footer .footer-top .footer-image {
    display: none;
  }
  footer .footer-top .footer-links {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 40.625rem) {
  footer {
    padding-top: 4em;
  }
  footer .footer-top .footer-links {
    flex-direction: column;
    gap: 1.8em;
  }
  footer .footer-top .footer-links div:last-child {
    flex-basis: unset;
  }
}
@media screen and (max-width: 30.3125rem) {
  footer .footer-top,
  footer .footer-bottom {
    padding: 0 2em;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-padding-top: 5.1em;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  overflow-x: hidden;
}
body.frozen {
  overflow: hidden;
}

h1, h2, h3, h4, h5 {
  margin: 0 0 0.5rem;
  line-height: 1.2;
  -webkit-hyphens: none;
  hyphens: none;
}

p {
  line-height: 1.3;
  -webkit-hyphens: none;
  hyphens: none;
}

h1, h2, h3 {
  font-family: "Playfair Display", serif;
}

h4, h5 {
  font-family: "Roboto", sans-serif;
}

h1 {
  font-weight: 400;
  letter-spacing: 3px;
  line-height: 1.1;
  /*font-size: clamp(2.5rem, 3.35vw, 4.1875rem);
  font-weight: 500;*/
}

h2 {
  font-size: clamp(2.2rem, 2.5vw, 3.125rem);
  font-weight: 500;
}

h3 {
  font-weight: 500;
  font-size: clamp(1.75rem, 1.65vw, 1.8rem);
}

h4 {
  font-size: clamp(1.2rem, 1.2vw, 1.5rem);
  font-weight: 500;
}

h5 {
  font-size: 1.1rem;
  font-weight: 400;
}

ul {
  list-style: none;
}

.disable-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
}

::-moz-selection {
  color: #f1f1e1;
  background: #DE5976;
}

::selection {
  color: #f1f1e1;
  background: #DE5976;
}

.socials-container {
  display: flex;
  gap: 0.7em;
  flex-wrap: wrap;
}
.socials-container .icon-with-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(226, 239, 233);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.15s linear 0s;
}
.socials-container .icon-with-bg:hover {
  background: rgb(196, 227, 212);
}
.socials-container .icon-with-bg:hover svg {
  transform: scale(1.1);
}
.socials-container .icon-with-bg svg {
  height: 20px;
  transition: all 0.15s linear 0s;
}

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
}
.error-page-wrapper {
  display: grid;
  place-items: center;
  padding: 0 2em;
  margin-top: 4em;
}
.error-page-wrapper h2 {
  width: 400px;
  text-align: center;
}
.error-page-wrapper p {
  margin-top: 1em;
}
.error-page-wrapper .button-fill-dark {
  margin-top: 2em;
}

@media screen and (max-width: 31.0625rem) {
  .error-page-wrapper h2 {
    width: 100%;
  }
}/*# sourceMappingURL=pageNotFound.css.map */