/* Box sizing rules */
html {
    box-sizing: border-box;
  }
    
  /* Set core root defaults */
  html:focus-within {
    scroll-behavior: smooth;
  }
  
  *, *::before, *::after {
    box-sizing: inherit;
  }
  
  /* Remove default margin */
  * {
    margin: 0;
    padding: 0;
    font: inherit;
  }

  /* Set core body defaults */
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeSpeed;
  }
  
  /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
  ul[role='list'],
  ol[role='list'] {
    list-style: none;
  }
  
  /* Make images, videos and icons easier to work with */
  img, picture, video, canvas, svg {
    display: block;
  }
  
  /* More sensible line-wrapping */
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
    hyphens: auto;
  }
  
  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }
  a{
    text-decoration: none;
  }
  
  /* React Framework Specific */
  #root, #__next {
    isolation: isolate;
  }
  
  /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
     scroll-behavior: auto;
    }
    
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }