$clr-white: #f1f1e1;
$clr-white-light: #f7f7eede;
$clr-black: #000;
$clr-primary: #E2EFE9;
$clr-secondary: #225B41;
$clr-accent-light-light: #f57893;
$clr-accent-light: #DE5976;
$clr-accent-dark: #C74561;
$clr-accent-dark-dark: #c03855;

$ff-heading: 'Playfair Display', serif;
$ff-text: 'Roboto', sans-serif;