.loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background: #fff;
  z-index: 10;
  img,
  div{
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  img{
    top: 50%;
  }   
  div{
    top: 70%;
  }
}