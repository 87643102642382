.accordion-item-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  color: $clr-white;
  background: $clr-secondary;
  border-radius: 7px;
  padding: .8em 1em;
  cursor: pointer;
  transition: .4s ease;
  h5{
    margin-bottom: 0;
    font-weight: 400;
  }
  img{
    transition: .4s ease;
  }
  &.active{
    background: $clr-accent-dark;
    img{
        transform: rotate(-180deg);
    }
  }
}

.collapse{
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .5s ease;
  margin: .6em 0 .5em;
  &.show{
    height: auto;
  }
}

.accordion-item-header,
.collapse{
  width: 100%;
}

@media screen and (min-width: 60.125rem){ // 962px
  .accordion-item-header,
  .collapse{
    width: 400px;
  }
}