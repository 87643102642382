footer{
  margin-top: 8em;  
  a{
    color: $clr-accent-light;
    font-weight: 400;
    &:hover{
      color: $clr-accent-dark;
    }
  }
  .footer-top,
  .footer-bottom{
    padding: 0 9em;  
  }
  .footer-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
    .footer-image{
      a{
        img{
          width: 300px;
        }
      }
    }
    .footer-links{
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      gap: 5em;
      div{
        &:last-child{  
          flex-basis: 33.33%;  
        }
        h5{
          font-weight: 500;
        }
        ul{
          li{
            margin-bottom: .2em;
          }
        }
      }
    }
  }
  .footer-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;
    margin-top: 3em;
    padding-top: .8em !important;
    padding-bottom: .5em !important;
    background: #D4E6DE;
  }
}

@media screen and (max-width: 83.125rem){ // 1330px
  footer{
    .footer-top,
    .footer-bottom{
      padding: 0 4em;    
    }  
  }
}

@media screen and (max-width: 71.875rem){ // 1150px
  footer{
    .footer-top{
      .footer-image{
        display: none;
      }
      .footer-links{
        justify-content: flex-start;
      }
    }
  }
}

@media screen and (max-width: 40.625rem){ // 650px
  footer{
    padding-top: 4em;
    .footer-top{
      .footer-links{
        flex-direction: column;
        gap: 1.8em;
        div{
          &:last-child{  
            flex-basis: unset; 
          }
        }
      }    
    }  
  }
}

@media screen and (max-width: 30.3125rem){ // 485px
  footer{
    .footer-top,
    .footer-bottom{
      padding: 0 2em;
    }  
  }
}