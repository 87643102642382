@import "../abstract/variable";

@import "./normalize";
@import "./button";
@import "./navigation";
@import "./loading";
@import "./carousel";
@import "./form";
@import "./popup";
@import "./footer";

*,
*::before,
*::after{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html{
  scroll-padding-top: 5.1em;
}

body{
  font-family: $ff-text;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  overflow-x: hidden;
  &.frozen{
    overflow: hidden;
  }
}

h1,h2,h3,h4,h5{
  margin: 0 0 .5rem;
  line-height: 1.2;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
p{
  line-height: 1.3;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
h1,h2,h3{
  font-family: $ff-heading;
}
h4,h5{
  font-family: $ff-text;
}

h1{
  font-weight: 400;
  letter-spacing: 3px;
  line-height: 1.1;
  /*font-size: clamp(2.5rem, 3.35vw, 4.1875rem);
  font-weight: 500;*/
}
h2{
  font-size: clamp(2.2rem, 2.5vw, 3.125rem);
  font-weight: 500;
}
h3{
  font-weight: 500;
  font-size: clamp(1.75rem, 1.6500000000000001vw, 1.8rem);
}
h4{
  font-size: clamp(1.2rem, 1.2vw, 1.5rem);
  font-weight: 500;
}
h5{
  font-size: 1.1rem;
  font-weight: 400;
}

ul{
  list-style: none;
}

.disable-select{
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
}

::selection{
  color: $clr-white;
  background: $clr-accent-light;
}

.socials-container{
  display: flex;
  gap: .7em;
  flex-wrap: wrap;
  .icon-with-bg{
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(226, 239, 233);
    width: 40px;
    height: 40px;
    border-radius: 50%; 
    transition: all 0.15s linear 0s;
    &:hover{
      background: rgb(196, 227, 212);  
      svg{
        transform: scale(1.1);
      }
    }
    svg{
      height: 20px;
      transition: all 0.15s linear 0s;
    }
  }
}