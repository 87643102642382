@import "../../components/global";

.error-page{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
  &-wrapper{
    display: grid;
    place-items: center;
    padding: 0 2em;
    margin-top: 4em;
    h2{
      width: 400px;
      text-align: center;
    }
    p{
      margin-top: 1em;
    }
    .button-fill-dark{
      margin-top: 2em;
    }
  }
}

@media screen and (max-width: 31.0625rem){ // 497px
  .error-page{
    &-wrapper{
      h2{
        width: 100%;
      }
    }
  }
}