.button-fill-light,
.button-fill-dark,
.button-fill-white,
.button-nofill-light{
  //padding: .5em 1.2em;
  padding: .6em 1.8em;
  border-radius: 10em;
  color: #fff;
  font-size: clamp(.9rem, 3vw, 1rem);
  font-family: $ff-text;
  font-weight: 400;
  &.none{
    display: none;
  }
  .cta-icon{
    display: inline-block;
    div{
      svg{
        path{
          stroke: #fff;
        }
      }
    }
  }
}

.button-nofill-light{
  color: $clr-black;
  border: 1px solid rgb(119, 117, 117);
  background: none;
  transition: .2s;
  &:hover{
    color: $clr-white;
    border-color: $clr-accent-light;
    background: $clr-accent-light;
  }
}
.button-fill-light,
.button-fill-dark{
  display: inline-flex;
  align-items: center;
  gap: .7em;
}
.button-fill-light{
  background: $clr-accent-light;
}
.button-fill-dark{
  background: $clr-accent-dark;
  transition: .2s;
  &:hover{
    background: $clr-accent-dark-dark;
  }
}
.button-fill-white{
  color: $clr-black;
  background: #fff;
  transition: .2s;
  &:hover{
    background: #fadae1;
  }
}

@media screen and (max-width: 65rem){ // 1040px
  .button-fill-light,
  .button-fill-dark{
    .cta-icon{
      display: inline-block;
      div{
        svg{
          height: 25px;
        }
      }
    } 
  }
}

button{
  cursor: pointer;
}

input[type=submit]{
  outline: none;
  border: none;
}