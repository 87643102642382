.popup{
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.514);
  opacity: 0;
  z-index: -1;
  backdrop-filter: blur(5px); 
  &.show{
    opacity: 1;
    z-index: 11;
  }
  .popup-box{
    margin: 0 1em;
    padding: 2em 3em;
    background: #fff;// #cbecdc;
    color: $clr-black;
    border-radius: 15px;
    .popup-icon{
      display: flex;
      justify-content: center;
      align-items: center;
      svg{
        width: min-content;
        height: 100px;
      }
    }   
    .popup-message{
      margin-top: 2em;
      text-align: center;
      p{
        font-weight: 400;
      }
      .button-nofill-light{
        font-size: .9em;
        margin-top: 2em;
      }
    }
  }
}