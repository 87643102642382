.splide{
  position: relative;
}

.splide__track{
  margin-bottom: 2em;
}

.splide__arrows{
  position: absolute;
  bottom: 30px;
  right: 50px;
  width: 120px;
  .splide__arrow{
    border: 1px solid $clr-secondary;
    border-radius: 50%;
    &:hover{
      svg{
        fill: #225B41;
      }
    }
    svg{
      width: 1.4em;
      height: 1.4em;
      fill: $clr-secondary;
    }
  }
}

.splide__pagination{
  .splide__pagination__page{
    background: $clr-primary;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    &:hover{
      background: $clr-accent-light;
    }
    &.is-active{
      background: $clr-accent-dark;
    }
  }
}

@media screen and (max-width: 36.25rem){ // 580px
  .splide{
    padding: 1em;
  }
  .splide__arrows{
    display: none;    
  }
  .splide__pagination{
    bottom: .2em;
    gap: .3em;
    .splide__pagination__page{
      width: 15px;
      height: 15px;
    }
  }
}