/* Box sizing rules */
html {
  box-sizing: border-box;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Set core body defaults */
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Make images, videos and icons easier to work with */
img, picture, video, canvas, svg {
  display: block;
}

/* More sensible line-wrapping */
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
}

/* React Framework Specific */
#root, #__next {
  isolation: isolate;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.button-fill-light,
.button-fill-dark,
.button-fill-white,
.button-nofill-light {
  padding: 0.6em 1.8em;
  border-radius: 10em;
  color: #fff;
  font-size: clamp(0.9rem, 3vw, 1rem);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.button-fill-light.none,
.button-fill-dark.none,
.button-fill-white.none,
.button-nofill-light.none {
  display: none;
}
.button-fill-light .cta-icon,
.button-fill-dark .cta-icon,
.button-fill-white .cta-icon,
.button-nofill-light .cta-icon {
  display: inline-block;
}
.button-fill-light .cta-icon div svg path,
.button-fill-dark .cta-icon div svg path,
.button-fill-white .cta-icon div svg path,
.button-nofill-light .cta-icon div svg path {
  stroke: #fff;
}

.button-nofill-light {
  color: #000;
  border: 1px solid rgb(119, 117, 117);
  background: none;
  transition: 0.2s;
}
.button-nofill-light:hover {
  color: #f1f1e1;
  border-color: #DE5976;
  background: #DE5976;
}

.button-fill-light,
.button-fill-dark {
  display: inline-flex;
  align-items: center;
  gap: 0.7em;
}

.button-fill-light {
  background: #DE5976;
}

.button-fill-dark {
  background: #C74561;
  transition: 0.2s;
}
.button-fill-dark:hover {
  background: #c03855;
}

.button-fill-white {
  color: #000;
  background: #fff;
  transition: 0.2s;
}
.button-fill-white:hover {
  background: #fadae1;
}

@media screen and (max-width: 65rem) {
  .button-fill-light .cta-icon,
  .button-fill-dark .cta-icon {
    display: inline-block;
  }
  .button-fill-light .cta-icon div svg,
  .button-fill-dark .cta-icon div svg {
    height: 25px;
  }
}
button {
  cursor: pointer;
}

input[type=submit] {
  outline: none;
  border: none;
}

header {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4em 4em;
  background: #E2EFE9;
  border-bottom: 1px solid #cbe6d9;
  z-index: 10;
  transition: 0.2s;
}
header.scrolled {
  background: #ffffff;
}
header.scrolled .site-logo {
  width: 100px;
}
header .site-logo {
  width: 120px;
  height: auto;
  z-index: 9;
  transition: 0.2s;
}
header .site-logo img {
  width: 100%;
  height: auto;
}
header .primary-navigation {
  z-index: 8;
  flex-grow: 70%;
}
header .primary-navigation .nav-ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.8em;
}
header .primary-navigation .nav-ul li {
  list-style: none;
}
header .primary-navigation .nav-ul li a {
  position: relative;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  color: #000;
  font-weight: 400;
}
header .primary-navigation .nav-ul li a::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  transform: scaleX(0);
  width: 100%;
  height: 2px;
  background-color: #C74561;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
header .primary-navigation .nav-ul li:hover a::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
header .primary-navigation .nav-ul li.active a::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
header .nav-actions {
  display: flex;
  align-items: center;
  gap: 1.2em;
  z-index: 8;
}
header .nav-actions .button-fill-dark {
  font-size: 1em;
  padding: 0.6em 1.8em;
}
header .nav-actions .burger-bar {
  position: relative;
  display: none;
  width: 30px;
  height: 30px;
  background: #225B41;
  border-radius: 50%;
  padding: 1.2em;
  cursor: pointer;
}
header .nav-actions .burger-bar .top-line,
header .nav-actions .burger-bar .mid-line,
header .nav-actions .burger-bar .bottom-line {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 45%;
  height: 2px;
  background: #f1f1e1;
  transition: 0.5s;
}
header .nav-actions .burger-bar .top-line {
  top: 35%;
}
header .nav-actions .burger-bar .mid-line {
  top: 50%;
}
header .nav-actions .burger-bar .bottom-line {
  top: 65%;
}
header .nav-actions .burger-bar.clicked .top-line,
header .nav-actions .burger-bar.clicked .mid-line {
  top: 50%;
  transform: translate(-50%, -50%) rotate(135deg);
  height: 1.5px;
}
header .nav-actions .burger-bar.clicked .bottom-line {
  top: 50%;
  transform: translate(-50%, -50%) rotate(225deg);
  height: 1.5px;
}

@media screen and (max-width: 1087px) {
  header {
    padding: 0.8em 1.3em;
  }
}
@media screen and (max-width: 890px) {
  header .site-logo {
    width: 100px;
  }
  header.header-clicked .primary-navigation {
    opacity: 1;
    pointer-events: auto;
  }
  header .primary-navigation {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 100dvh;
    background: #E2EFE9;
    transition: 0.2s cubic-bezier(0.1, 0.1, 0.5, 0.9);
    opacity: 0;
    pointer-events: none;
  }
  header .primary-navigation .nav-ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5em;
    margin-left: 1.7em;
  }
  header .primary-navigation .nav-ul li a {
    font-size: 2em;
    font-weight: 500;
  }
  header .primary-navigation .nav-ul li a::after {
    height: 3px;
  }
  header .nav-actions {
    gap: 1.5em;
  }
  header .nav-actions .button-fill-dark {
    padding: 0.5em 1.4em;
  }
  header .nav-actions .burger-bar {
    display: block;
  }
}
.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background: #fff;
  z-index: 10;
}
.loader img,
.loader div {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader img {
  top: 50%;
}
.loader div {
  top: 70%;
}

.splide {
  position: relative;
}

.splide__track {
  margin-bottom: 2em;
}

.splide__arrows {
  position: absolute;
  bottom: 30px;
  right: 50px;
  width: 120px;
}
.splide__arrows .splide__arrow {
  border: 1px solid #225B41;
  border-radius: 50%;
}
.splide__arrows .splide__arrow:hover svg {
  fill: #225B41;
}
.splide__arrows .splide__arrow svg {
  width: 1.4em;
  height: 1.4em;
  fill: #225B41;
}

.splide__pagination .splide__pagination__page {
  background: #E2EFE9;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}
.splide__pagination .splide__pagination__page:hover {
  background: #DE5976;
}
.splide__pagination .splide__pagination__page.is-active {
  background: #C74561;
}

@media screen and (max-width: 36.25rem) {
  .splide {
    padding: 1em;
  }
  .splide__arrows {
    display: none;
  }
  .splide__pagination {
    bottom: 0.2em;
    gap: 0.3em;
  }
  .splide__pagination .splide__pagination__page {
    width: 15px;
    height: 15px;
  }
}
form {
  background: #cbecdc;
  padding: 2em;
  border-radius: 15px;
}
form .input-wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 2em;
  margin-bottom: 1em;
}
form .input-wrapper .input-content {
  display: grid;
  width: 100%;
}
form .input-wrapper .input-content label {
  font-size: 0.95em;
  font-weight: 400;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
  color: #000;
  margin-bottom: 0;
}
form .input-wrapper .input-content label::after {
  content: "*";
  position: relative;
  bottom: 5px;
  margin-left: 0.2em;
  color: #c03855;
  font-size: 0.9em;
}
form .input-wrapper .input-content input[type=text],
form .input-wrapper .input-content input[type=email],
form .input-wrapper .input-content textarea {
  width: 100%;
  padding: 0.5em;
  margin-top: 0.5em;
  color: #000;
  font-weight: 400;
  background: none;
  outline: none;
  border: 1px solid rgb(119, 117, 117);
  border-radius: 5px;
  transition: 0.3s;
}
form .input-wrapper .input-content input[type=text]:hover,
form .input-wrapper .input-content input[type=email]:hover,
form .input-wrapper .input-content textarea:hover {
  border: 1px solid #000;
}
form .input-wrapper .input-content input[type=text]:not(:-moz-placeholder-shown), form .input-wrapper .input-content input[type=email]:not(:-moz-placeholder-shown), form .input-wrapper .input-content textarea:not(:-moz-placeholder-shown) {
  border: 1px solid #000;
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.08);
}
form .input-wrapper .input-content input[type=text]:focus, form .input-wrapper .input-content input[type=text]:not(:placeholder-shown),
form .input-wrapper .input-content input[type=email]:focus,
form .input-wrapper .input-content input[type=email]:not(:placeholder-shown),
form .input-wrapper .input-content textarea:focus,
form .input-wrapper .input-content textarea:not(:placeholder-shown) {
  border: 1px solid #000;
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.08);
}
form .input-wrapper .input-content textarea {
  resize: vertical;
}
form .form-message {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 0.2em;
  color: #ef7d60;
  margin-top: 0.5em;
}
form .form-message svg {
  width: 15px;
  height: 15px;
  fill: #ef7d60;
}
form input[type=submit] {
  font-size: 1em;
  cursor: pointer;
  margin-top: 1em;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.514);
  opacity: 0;
  z-index: -1;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.popup.show {
  opacity: 1;
  z-index: 11;
}
.popup .popup-box {
  margin: 0 1em;
  padding: 2em 3em;
  background: #fff;
  color: #000;
  border-radius: 15px;
}
.popup .popup-box .popup-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup .popup-box .popup-icon svg {
  width: -moz-min-content;
  width: min-content;
  height: 100px;
}
.popup .popup-box .popup-message {
  margin-top: 2em;
  text-align: center;
}
.popup .popup-box .popup-message p {
  font-weight: 400;
}
.popup .popup-box .popup-message .button-nofill-light {
  font-size: 0.9em;
  margin-top: 2em;
}

footer {
  margin-top: 8em;
}
footer a {
  color: #DE5976;
  font-weight: 400;
}
footer a:hover {
  color: #C74561;
}
footer .footer-top,
footer .footer-bottom {
  padding: 0 9em;
}
footer .footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
}
footer .footer-top .footer-image a img {
  width: 300px;
}
footer .footer-top .footer-links {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 5em;
}
footer .footer-top .footer-links div:last-child {
  flex-basis: 33.33%;
}
footer .footer-top .footer-links div h5 {
  font-weight: 500;
}
footer .footer-top .footer-links div ul li {
  margin-bottom: 0.2em;
}
footer .footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-top: 3em;
  padding-top: 0.8em !important;
  padding-bottom: 0.5em !important;
  background: #D4E6DE;
}

@media screen and (max-width: 83.125rem) {
  footer .footer-top,
  footer .footer-bottom {
    padding: 0 4em;
  }
}
@media screen and (max-width: 71.875rem) {
  footer .footer-top .footer-image {
    display: none;
  }
  footer .footer-top .footer-links {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 40.625rem) {
  footer {
    padding-top: 4em;
  }
  footer .footer-top .footer-links {
    flex-direction: column;
    gap: 1.8em;
  }
  footer .footer-top .footer-links div:last-child {
    flex-basis: unset;
  }
}
@media screen and (max-width: 30.3125rem) {
  footer .footer-top,
  footer .footer-bottom {
    padding: 0 2em;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-padding-top: 5.1em;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  overflow-x: hidden;
}
body.frozen {
  overflow: hidden;
}

h1, h2, h3, h4, h5 {
  margin: 0 0 0.5rem;
  line-height: 1.2;
  -webkit-hyphens: none;
  hyphens: none;
}

p {
  line-height: 1.3;
  -webkit-hyphens: none;
  hyphens: none;
}

h1, h2, h3 {
  font-family: "Playfair Display", serif;
}

h4, h5 {
  font-family: "Roboto", sans-serif;
}

h1 {
  font-weight: 400;
  letter-spacing: 3px;
  line-height: 1.1;
  /*font-size: clamp(2.5rem, 3.35vw, 4.1875rem);
  font-weight: 500;*/
}

h2 {
  font-size: clamp(2.2rem, 2.5vw, 3.125rem);
  font-weight: 500;
}

h3 {
  font-weight: 500;
  font-size: clamp(1.75rem, 1.65vw, 1.8rem);
}

h4 {
  font-size: clamp(1.2rem, 1.2vw, 1.5rem);
  font-weight: 500;
}

h5 {
  font-size: 1.1rem;
  font-weight: 400;
}

ul {
  list-style: none;
}

.disable-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
}

::-moz-selection {
  color: #f1f1e1;
  background: #DE5976;
}

::selection {
  color: #f1f1e1;
  background: #DE5976;
}

.socials-container {
  display: flex;
  gap: 0.7em;
  flex-wrap: wrap;
}
.socials-container .icon-with-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(226, 239, 233);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.15s linear 0s;
}
.socials-container .icon-with-bg:hover {
  background: rgb(196, 227, 212);
}
.socials-container .icon-with-bg:hover svg {
  transform: scale(1.1);
}
.socials-container .icon-with-bg svg {
  height: 20px;
  transition: all 0.15s linear 0s;
}

.accordion-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  color: #f1f1e1;
  background: #225B41;
  border-radius: 7px;
  padding: 0.8em 1em;
  cursor: pointer;
  transition: 0.4s ease;
}
.accordion-item-header h5 {
  margin-bottom: 0;
  font-weight: 400;
}
.accordion-item-header img {
  transition: 0.4s ease;
}
.accordion-item-header.active {
  background: #C74561;
}
.accordion-item-header.active img {
  transform: rotate(-180deg);
}

.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease;
  margin: 0.6em 0 0.5em;
}
.collapse.show {
  height: auto;
}

.accordion-item-header,
.collapse {
  width: 100%;
}

@media screen and (min-width: 60.125rem) {
  .accordion-item-header,
  .collapse {
    width: 400px;
  }
}
#uvod {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  width: 100%;
  background: #E2EFE9;
  padding: 4em 17em 4em 6em;
}
#uvod .uvod-text {
  transform: translateY(-40%);
  z-index: 2;
}
#uvod .uvod-text h1 {
  font-size: 6.1em;
}
#uvod .uvod-text p {
  margin: 1em 0 2em;
  font-size: 1.4em;
}
#uvod .uvod-foto {
  position: relative;
  width: 60%;
  height: 80%;
  z-index: 2;
}
#uvod .uvod-foto img {
  border-radius: 15px;
  width: 100%;
  height: 100%;
}
#uvod .uvod-hero {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}

@media screen and (max-width: 106.25rem) {
  #uvod {
    padding: 5em 6em;
  }
  #uvod .uvod-text h1 {
    font-size: 5.5em;
  }
}
@media screen and (max-width: 97.5rem) {
  #uvod {
    padding: 4em 4em;
  }
  #uvod .uvod-text h1 {
    font-size: 5.3em;
  }
  #uvod .uvod-foto {
    position: relative;
    width: 80%;
  }
}
@media screen and (max-width: 97.5rem) {
  #uvod .uvod-text h1 {
    font-size: 5em;
  }
  #uvod .uvod-foto {
    width: 80%;
  }
}
@media screen and (max-width: 77.5rem) {
  #uvod .uvod-text h1 {
    font-size: 4em;
  }
}
@media screen and (max-width: 65rem) {
  #uvod .uvod-text h1 {
    font-size: 3.7em;
  }
}
@media screen and (max-width: 65rem) {
  #uvod {
    flex-wrap: wrap;
  }
  #uvod .uvod-text {
    transform: translateY(0);
    width: 100%;
    margin-top: 2em;
  }
  #uvod .uvod-text h1 {
    width: 60%;
    font-size: 3.7em;
  }
  #uvod .uvod-text p {
    margin: 1em 0 1.3em;
  }
  #uvod .uvod-foto {
    width: 70%;
    margin: 3em 0 0 auto;
  }
}
@media screen and (max-width: 55.625rem) {
  #uvod .uvod-text h1 {
    width: 80%;
  }
  #uvod .uvod-foto {
    width: 90%;
  }
}
@media screen and (max-width: 43.9375rem) {
  #uvod .uvod-text h1 {
    width: 100%;
  }
}
@media screen and (max-width: 37rem) {
  #uvod {
    padding: 2em;
  }
  #uvod .uvod-text h1 {
    font-size: 3em;
  }
  #uvod .uvod-foto {
    margin-top: 1em;
    width: 100%;
  }
}
#benefity {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5em;
  width: 60%;
  margin: 8em auto 0 auto;
  padding: 4em 4em 10em 4em;
  color: #f1f1e1;
  background: #225B41;
  border-radius: 10px;
}
#benefity h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
#benefity .skills {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 5em;
  width: 100%;
  padding: 0 2em;
}
#benefity .skills div {
  text-align: center;
}
#benefity .skills div p {
  white-space: nowrap;
}

@media screen and (max-width: 1600px) {
  #benefity {
    width: 75%;
  }
}
@media screen and (max-width: 65rem) {
  #benefity {
    width: 90%;
    flex-wrap: wrap;
    gap: 1em;
  }
  #benefity h2 {
    max-width: unset;
    text-align: center;
  }
  #benefity p {
    max-width: unset;
  }
}
@media screen and (max-width: 678px) {
  #benefity .skills {
    gap: 3em;
  }
}
@media screen and (max-width: 579px) {
  #benefity .skills {
    bottom: 5%;
    gap: 2em;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 523px) {
  #benefity {
    padding: 2em 2em 15em 2em;
  }
  #benefity .skills {
    bottom: -5%;
  }
}
#moje-sluzby {
  width: 100%;
  padding: 4em 2em;
  margin-top: 8em;
  background: #E2EFE9;
  /*
  .programs-services{
    display: flex;
    justify-content: center;
    gap: 2em;
    flex-wrap: wrap;
    margin-top: 3em;
    .program-service{
      background: #cfeadd;
      width: 600px;
      padding: 3em 4em;
      border-radius: 10px;
      -webkit-box-shadow: 5px 7px 0px 4px rgba(0, 0, 0, 0.06);
      -moz-box-shadow: 5px 7px 0px 4px rgba(0, 0, 0, 0.06);
      box-shadow: 5px 7px 0px 4px rgba(0, 0, 0, 0.06);
      h3{
        text-align: center;
      }
      ul{
        margin-top: 2em;
        list-style-position: outside;
        li{
          position: relative;
          margin-top: 1em;
          &.bold-list-element{
            font-weight: 400;
          }
          &::before{
            content: '';
            position: absolute;
            top: .2em;
            left: -1.5em;
            width: 10em;
            height: 10em;
            background-repeat: no-repeat;
            background-image: url("../../../images/arrow-right.svg");
          }
        }
      }
    }
  }*/
}
#moje-sluzby h2 {
  text-align: center;
}
#moje-sluzby p {
  text-align: center;
  margin: 1em auto 0;
}
#moje-sluzby h3 {
  text-align: center;
  margin-top: 1em;
}
#moje-sluzby .program-services {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2em;
  flex-wrap: wrap;
  margin-top: 2em;
}
#moje-sluzby .program-services .program-service {
  position: relative;
  width: 400px;
  padding: 3.2em 2.5em;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 20px 8px rgba(0, 0, 0, 0.04);
  /*-webkit-box-shadow: 5px 7px 0px 4px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 5px 7px 0px 4px rgba(0, 0, 0, 0.06);
  box-shadow: 5px 7px 0px 4px rgba(0, 0, 0, 0.06);*/
}
#moje-sluzby .program-services .program-service.popular {
  background: #c03855;
}
#moje-sluzby .program-services .program-service.popular .popular-sign {
  position: absolute;
  top: 0;
  right: 0;
  background: #F9D375;
  padding: 0.3em 0.8em;
  border-radius: 0 18px 0 10px;
  font-weight: 400;
}
#moje-sluzby .program-services .program-service.popular h4,
#moje-sluzby .program-services .program-service.popular .bold,
#moje-sluzby .program-services .program-service.popular .services-list {
  color: #fff;
}
#moje-sluzby .program-services .program-service.popular .services-list li::before {
  background-image: url("../../../images/check-green-light.svg");
}
#moje-sluzby .program-services .program-service.popular .dotted-line {
  border-bottom: 2px dashed #D5D5D5;
}
#moje-sluzby .program-services .program-service h4 {
  font-size: clamp(1.5rem, 1.5vw, 1.9rem);
}
#moje-sluzby .program-services .program-service .bold {
  text-align: left;
  font-size: 1.2em;
  font-weight: 400;
  margin-top: 1.2em;
}
#moje-sluzby .program-services .program-service .services-list {
  position: relative;
  margin: 0.5em 0 0 2em;
}
#moje-sluzby .program-services .program-service .services-list li {
  position: relative;
  margin-bottom: 1em;
}
#moje-sluzby .program-services .program-service .services-list li::before {
  content: "";
  position: absolute;
  top: 0.2em;
  left: -1.8em;
  width: 1em;
  height: 1em;
  background-repeat: no-repeat;
  background-image: url("../../../images/check-green-dark.svg");
}
#moje-sluzby .program-services .program-service .dotted-line {
  margin: 1.7em 0;
  border-bottom: 2px dashed #707070;
}
#moje-sluzby .program-services .program-service a {
  display: block;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 82.0625rem) {
  #moje-sluzby .programs-services .program-service {
    width: 500px;
  }
}
@media screen and (max-width: 82.0625rem) {
  #moje-sluzby .programs-services .program-service {
    width: 70%;
  }
}
@media screen and (max-width: 69.5rem) {
  #moje-sluzby {
    padding: 3em 1em;
  }
  #moje-sluzby p {
    max-width: 100%;
  }
  #moje-sluzby .programs-services .program-service {
    width: 100%;
    padding: 2em;
  }
  #moje-sluzby .programs-services .program-service ul {
    margin: 0 1em;
  }
}
#s-cim-vam-pomohu {
  position: relative;
  width: 80%;
  margin: 8em auto 0;
  padding: 4em;
  color: #f1f1e1;
}
#s-cim-vam-pomohu::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 85%;
  height: 100%;
  background: #225B41;
  border-radius: 10px;
  z-index: -1;
}
#s-cim-vam-pomohu .pomoc-content {
  max-width: 60%;
}
#s-cim-vam-pomohu .pomoc-content p {
  max-width: 80%;
  margin: 1.3em 0 2em;
}
#s-cim-vam-pomohu .pomoc-content .checkpoints {
  max-width: 80%;
  margin: 2em 0 2em 1.5em;
  list-style-image: url("../../../images/check-light.svg");
  list-style-position: outside;
}
#s-cim-vam-pomohu .pomoc-content .checkpoints li:not(:first-child) {
  padding-top: 1em;
}
#s-cim-vam-pomohu .pomoc-content .cta-paragraph {
  margin-bottom: 1em;
}
#s-cim-vam-pomohu .pomoc-foto {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
#s-cim-vam-pomohu .pomoc-foto svg {
  width: 450px;
  height: 500px;
}

@media screen and (max-width: 92.5rem) {
  #s-cim-vam-pomohu {
    width: 70%;
  }
}
@media screen and (max-width: 83.4375rem) {
  #s-cim-vam-pomohu {
    width: 85%;
  }
}
@media screen and (max-width: 67.5rem) {
  #s-cim-vam-pomohu {
    width: 90%;
  }
  #s-cim-vam-pomohu .pomoc-foto svg {
    width: 400px;
    height: 400px;
  }
}
@media screen and (max-width: 51.5625rem) {
  #s-cim-vam-pomohu .pomoc-content {
    max-width: 80%;
  }
  #s-cim-vam-pomohu .pomoc-foto svg {
    width: 350px;
    height: 400px;
  }
}
@media screen and (max-width: 51.5625rem) {
  #s-cim-vam-pomohu {
    padding: 2em;
  }
  #s-cim-vam-pomohu::after {
    width: 100%;
  }
  #s-cim-vam-pomohu .pomoc-content {
    max-width: 100%;
  }
  #s-cim-vam-pomohu .pomoc-content p {
    max-width: 100%;
  }
  #s-cim-vam-pomohu .pomoc-foto svg {
    display: none;
  }
}
#o-mne {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8em;
  margin-top: 8em;
  padding: 4em 6em;
  background: #E2EFE9;
  overflow: hidden;
}
#o-mne .o-mne-content {
  width: 60%;
  z-index: 2;
}
#o-mne .o-mne-content p {
  margin: 1.7em 0 3em;
}
#o-mne .o-mne-foto {
  position: relative;
  z-index: 2;
  width: 70%;
  height: 80%;
}
#o-mne .o-mne-foto img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
#o-mne .o-mne-background {
  position: absolute;
  top: 80%;
  left: 10%;
  width: 30%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

@media screen and (max-width: 66.75rem) {
  #o-mne {
    padding: 5em 3em 6em 3em;
  }
  #o-mne .o-mne-content {
    width: 70%;
  }
}
@media screen and (max-width: 66.75rem) {
  #o-mne {
    gap: 4em;
  }
  #o-mne .o-mne-content,
  #o-mne .o-mne-foto {
    width: 90%;
  }
}
@media screen and (max-width: 66.75rem) {
  #o-mne {
    padding: 5em 2em 6em 2em;
    flex-wrap: wrap;
  }
  #o-mne .o-mne-content,
  #o-mne .o-mne-foto {
    width: 97%;
  }
}
/*
@media screen and (max-width: 62.5rem){ // 1000px
  #o-mne{
    .o-mne-content{
      max-width: 70%;
    }  
  }
}
@media screen and (max-width: 38.125rem){ // 610px
  #o-mne{
    .o-mne-content{
      max-width: 100%;
    }  
  }
}*/
#jak-to-u-me-funguje {
  margin-top: 8em;
}
#jak-to-u-me-funguje h2 {
  text-align: center;
}
#jak-to-u-me-funguje .jak-to-funguje-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 1.5em;
  flex-wrap: wrap;
  margin: 3em auto 0 auto;
  width: 60%;
}
#jak-to-u-me-funguje .jak-to-funguje-container .step {
  position: relative;
  width: 350px;
  height: 220px;
  padding: 2em;
  background: #E2EFE9;
  border-radius: 10px;
}
#jak-to-u-me-funguje .jak-to-funguje-container .step .step-number {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  font-size: 12em;
  font-weight: 500;
  margin-bottom: 0.2em;
  color: #d8e6df;
  z-index: 1;
}
#jak-to-u-me-funguje .jak-to-funguje-container .step h3,
#jak-to-u-me-funguje .jak-to-funguje-container .step p {
  position: relative;
  z-index: 2;
}
#jak-to-u-me-funguje .jak-to-funguje-container .step p {
  margin-top: 1em;
}

@media screen and (max-width: 114.375rem) {
  #jak-to-u-me-funguje .jak-to-funguje-container {
    width: 80%;
  }
}
@media screen and (max-width: 114.375rem) {
  #jak-to-u-me-funguje .jak-to-funguje-container {
    width: 90%;
  }
}
@media screen and (max-width: 114.375rem) {
  #jak-to-u-me-funguje {
    padding: 0 2em;
  }
  #jak-to-u-me-funguje .jak-to-funguje-container {
    width: 100%;
  }
}
@media screen and (max-width: 52.5rem) {
  #jak-to-u-me-funguje .jak-to-funguje-container div {
    width: 100%;
  }
}
#reference {
  margin-top: 8em;
  padding-top: 4em;
}
#reference h2 {
  text-align: center;
}
#reference h3 {
  padding-left: 2em;
  margin: 1.5em 0 1em;
}
#reference .reference-container:nth-of-type(2) {
  padding: 0 3em;
}
#reference .reference-container .reference-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2em;
  background: #E2EFE9;
  border-radius: 10px;
  cursor: pointer;
}
#reference .reference-container .reference-card .reference-text {
  width: 100%;
}
#reference .reference-container .reference-card .reference-text p {
  margin-bottom: 1em;
}
#reference .reference-container .reference-card img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#reference .reference-call-to-action {
  text-align: center;
  margin-top: 3em;
}

@media screen and (max-width: 75rem) {
  #reference .reference-container .reference-card {
    flex-wrap: wrap;
    gap: 3em;
  }
  #reference .reference-container .reference-card .reference-text {
    width: 100%;
  }
  #reference .reference-container .reference-card .reference-image {
    width: 100%;
  }
}
@media screen and (max-width: 36.25rem) {
  #reference h3 {
    text-align: center;
    padding: 0;
    margin: 2em 0 0.7em;
  }
  #reference .reference-container:nth-of-type(2) {
    padding: 1em;
  }
}
.green-background {
  background: #E2EFE9;
  padding: 0 4em 4em 4em;
  margin-top: 8em;
}

@media screen and (max-width: 39.5rem) {
  .green-background {
    padding: 5em 1.5em;
  }
}
#casto-kladene-dotazy {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 5em;
  flex-wrap: wrap;
  padding-top: 4em;
}
#casto-kladene-dotazy .faq-heading h2 {
  margin-bottom: 1em;
}

#oteviraci-doba {
  display: grid;
  place-items: center;
  margin-top: 6em;
}
#oteviraci-doba h2,
#oteviraci-doba p {
  text-align: center;
}
#oteviraci-doba p {
  max-width: 50%;
  margin: 1em auto 0;
}
#oteviraci-doba .button-fill-dark {
  margin-top: 1.8em;
}

@media screen and (max-width: 52.5rem) {
  #oteviraci-doba p {
    max-width: 90%;
  }
}
#cenik {
  margin-top: 8em;
  padding-top: 4em;
}
#cenik h2 {
  text-align: center;
}
#cenik .cenik-sluzeb-container {
  margin-top: 2em;
}
#cenik .cenik-sluzeb-container .cenik-sluzeb-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 60%;
  margin: 1em auto 0;
}
#cenik .cenik-sluzeb-container .cenik-sluzeb-item .popis-ceny-sluzby h4 {
  margin: 0;
}
#cenik .cenik-sluzeb-container .cenik-sluzeb-item .dots {
  flex-grow: 3;
  border-bottom: 2px dotted #000;
  margin: 0 0.5em;
}
#cenik .cenik-sluzeb-container .cenik-sluzeb-item .cena-sluzby {
  white-space: nowrap;
}

@media screen and (max-width: 79.375rem) {
  #cenik .cenik-sluzeb-container .cenik-sluzeb-item {
    width: 80%;
  }
  #cenik .cenik-sluzeb-container .cenik-sluzeb-item .dots {
    margin: 0 1em;
  }
}
@media screen and (max-width: 53.75rem) {
  #cenik .cenik-sluzeb-container .cenik-sluzeb-item {
    width: 90%;
  }
}
#kontakt {
  margin-top: 8em;
  padding: 4em;
  color: #f1f1e1;
  background: #225B41;
}
#kontakt .kontakt-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 2em;
}
#kontakt .kontakt-container .kontakt-left {
  width: 40%;
}
#kontakt .kontakt-container .kontakt-left .kontakt-ponuka {
  margin-top: 2em;
  color: rgba(247, 247, 238, 0.8705882353);
}
#kontakt .kontakt-container .kontakt-left .kontakt-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 3em;
  margin-top: 5em;
}
#kontakt .kontakt-container .kontakt-left .kontakt-info div p {
  width: 70%;
  color: rgba(247, 247, 238, 0.8705882353);
}
#kontakt .kontakt-container .kontakt-left .kontakt-info div ul {
  list-style: none;
}
#kontakt .kontakt-container .kontakt-left .kontakt-info div ul li a {
  color: rgba(247, 247, 238, 0.8705882353);
  transition: 0.2s;
}
#kontakt .kontakt-container .kontakt-left .kontakt-info div ul li a:hover {
  color: #f57893;
}
#kontakt .kontakt-container .kontakt-left .kontakt-info div ul li:nth-child(3) {
  margin-top: 1em;
}
#kontakt .kontakt-container .kontakt-left .kontakt-info div ul li:nth-child(3) .light-color {
  color: rgba(247, 247, 238, 0.8705882353);
}
#kontakt .kontakt-container .kontakt-left .kontakt-socials {
  margin-top: 2.5em;
}
#kontakt .kontakt-container .kontakt-right {
  width: 30%;
  margin-top: 4em;
}
#kontakt .map {
  margin-top: 4em;
  border: none;
  border-radius: 10px;
  width: 100%;
}

@media screen and (max-width: 73.125rem) {
  #kontakt {
    padding: 4em 2em;
  }
  #kontakt .kontakt-container {
    flex-wrap: wrap;
  }
  #kontakt .kontakt-container .kontakt-left,
  #kontakt .kontakt-container .kontakt-right {
    width: 100%;
  }
}/*# sourceMappingURL=home.css.map */