form{
  background: #cbecdc;
  padding: 2em;
  border-radius: 15px;
  .input-wrapper{
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 2em;
    margin-bottom: 1em;
    .input-content{
      display: grid;
      width: 100%;
      label{
        font-size: .95em;
        font-weight: 400;
        width: min-content;
        white-space: nowrap;
        color: $clr-black;
        margin-bottom: 0;
        &::after{
          content: '*';
          position: relative;
          bottom: 5px;
          margin-left: .2em;
          color: $clr-accent-dark-dark;
          font-size: .9em;
        }
      }
      input[type=text],
      input[type=email],
      textarea{
        width: 100%;
        padding: .5em;
        margin-top: .5em;
        color: $clr-black;
        font-weight: 400;
        background: none;
        outline: none;
        border: 1px solid rgb(119, 117, 117);
        border-radius: 5px;
        transition: .3s;
        &:hover{
          border: 1px solid $clr-black;  
        }
        &:focus,
        &:not(:placeholder-shown){
          border: 1px solid $clr-black;
          -webkit-box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.08);
          -moz-box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.08);
        }
      }
      textarea{
        resize: vertical;
      }
    }  
  }
  .form-message{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: .2em;
    color: #ef7d60;
    margin-top: .5em;
    svg{
      width: 15px;
      height: 15px;
      fill: #ef7d60;
    }
  }
  input[type=submit]{
    font-size: 1em;
    cursor: pointer;
    margin-top: 1em;
  }
}